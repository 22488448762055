import { Options, Vue } from "vue-class-component";
import PerfectScrollbar from "perfect-scrollbar";
import menu from "./menu";

@Options({
  props: {
  },
})
export default class SideMenu extends Vue {
  menus: any = [];

  mounted() {
    // new PerfectScrollbar("#sidenav-main");
    this.menus = menu.map((m: any) => {
      const temp = { ...m };
      temp.class = temp.class.replace("text-primary", "");
      return temp;
    });
  }

  toggleMenu() {
    document.body.className = this.isClosed() ?'nav-open g-sidenav-show g-sidenav-pinned' : 'g-sidenav-hidden';
  }

  isClosed() {
    return document.body.className == 'g-sidenav-hidden';
  }

  isActive(route, menu) {
    return route.path.includes(menu) ? "active" : "";
  }
}