import { Options, Vue } from "vue-class-component";
import PerfectScrollbar from "perfect-scrollbar";
import menu from "@/components/side-menu/menu";
import environment from "@/config";

@Options({
  props: {
  },
})
export default class SideMenu extends Vue {
  menus: any = [];

  logo() {
    return environment.white_label.asset.logo;
  }

  mounted() {
    // new PerfectScrollbar("#sidenav-main");
    this.menus = menu;
  }

  closeMenu() {
    document.body.className = 'g-sidenav-hidden'
  }

  isActive(route, menu) {
    return route.path.includes(menu) ? "active" : "";
  }
}