import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4999a51a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "docs-normal" }
const _hoisted_3 = {
  key: 2,
  class: "navbar-nav"
}
const _hoisted_4 = { class: "nav-item" }
const _hoisted_5 = { class: "nav-link-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_SideMenu = _resolveComponent("SideMenu", true)!

  return (_openBlock(), _createBlock(_component_SideMenu, null, {
    logo: _withCtx(() => [
      _createVNode(_component_router_link, {
        to: "/",
        class: ""
      }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            src: _ctx.logo(),
            class: "navbar-brand-img",
            alt: "..."
          }, null, 8, _hoisted_1)
        ]),
        _: 1
      })
    ]),
    item: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menus, (menuContainer) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: "menuContainer" }, [
          (menuContainer.type == 'divider')
            ? (_openBlock(), _createElementBlock("hr", {
                class: "my-3",
                key: menuContainer.type
              }))
            : _createCommentVNode("", true),
          (menuContainer.type == 'header')
            ? (_openBlock(), _createElementBlock("h6", {
                class: "navbar-heading p-0 text-muted",
                key: menuContainer.type
              }, [
                _createElementVNode("span", _hoisted_2, _toDisplayString(menuContainer.name), 1)
              ]))
            : _createCommentVNode("", true),
          (menuContainer.type == 'single')
            ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
                _createElementVNode("li", _hoisted_4, [
                  (typeof menuContainer.route == 'string')
                    ? (_openBlock(), _createBlock(_component_router_link, {
                        key: 0,
                        to: menuContainer.route,
                        class: _normalizeClass(["nav-link", _ctx.isActive(_ctx.$route, menuContainer.route)])
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("i", {
                            class: _normalizeClass(menuContainer.class)
                          }, null, 2),
                          _createElementVNode("span", _hoisted_5, _toDisplayString(menuContainer.name), 1)
                        ]),
                        _: 2
                      }, 1032, ["to", "class"]))
                    : _createCommentVNode("", true)
                ])
              ]))
            : _createCommentVNode("", true)
        ], 64))
      }), 128))
    ]),
    _: 1
  }))
}